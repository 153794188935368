import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Props as SlideProps, Slide } from './Slide'

export interface Props {
  cta?: ButtonProps
  description?: string
  slides?: SlideProps[]
  title?: string
}

function PrevArrow(props: any) {
  return (
    <Arrow className="slider-prev" dial={5} onClick={props.onClick}>
      <ArrowLeft />
    </Arrow>
  )
}

function NextArrow(props: any) {
  return (
    <Arrow className="slider-next" dial={5} onClick={props.onClick}>
      <ArrowRight />
    </Arrow>
  )
}

export const MainSlider = memo(function MainSlider({
  cta,
  description,
  slides,
  title,
}: Props) {
  const settings = {
    fade: true,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 2000,
  }

  if (!slides) {
    return null
  }

  return (
    <Container>
      {title ? (
        <Fade bottom distance="60px">
          <Title>{title}</Title>
        </Fade>
      ) : null}
      {description ? (
        <Fade bottom distance="60px">
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Fade>
      ) : null}
      <Fade bottom distance="60px">
        <Carousel>
          <Slider lazyLoad="progressive" {...settings}>
            {slides.map((item, index) => (
              <Slide key={index} {...item} />
            ))}
          </Slider>
          {cta ? <CTA {...cta} /> : null}
        </Carousel>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 5.25rem 10vw 7.5rem;

  .slick-slider {
    margin-top: 3.75rem;
  }

  @media (max-width: 1199px) {
    padding: 2.75rem 8.454vw 3.75rem;

    .slick-slider {
      margin-top: 1.875rem;
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
  margin-bottom: 0.75rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 0;
    text-align: center;
  }
`

const Description = styled.div`
  max-width: 860px;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.875rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Carousel = styled.div`
  position: relative;
`

const CTA = styled(Button)`
  background: ${theme.colors.variants.primaryLight1};
  position: absolute;
  bottom: 3.75rem;
  left: 3.75rem;
  &:after {
    background: ${theme.colors.variants.neutralDark1};
  }

  @media (max-width: 1199px) {
    bottom: 1.875rem;
    left: 1.875rem;
  }
`

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 3.75rem;
  z-index: 3;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    border-color: transparent;
  }
  &.slider-prev {
    bottom: 3.75rem;
  }
  &.slider-next {
    bottom: 9.75rem;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`
