import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

import { Block, Props as BlockProps } from './Block'

export interface Props {
  blocks: BlockProps[]
  cta?: ButtonProps
  description?: string
  hashtag?: string
  title?: string
}

export const BeachParagraph = memo(function BeachParagraph({
  blocks,
  cta,
  description,
  hashtag,
  title,
}: Props) {
  if (blocks.length < 1) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {hashtag ? (
          <Media lessThan="desktopSmall">
            <Label>{hashtag}</Label>
          </Media>
        ) : null}
        {title ? (
          <Fade bottom distace="3.75rem">
            <Title>{title}</Title>
          </Fade>
        ) : null}
        <Text row wrap>
          {description ? (
            <Fade bottom distace="3.75rem">
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </Fade>
          ) : null}
          {hashtag ? (
            <Media greaterThanOrEqual="desktopSmall">
              <ParallaxProvider>
                <Hashtag x={[15, 0]}>{hashtag}</Hashtag>
              </ParallaxProvider>
            </Media>
          ) : null}
        </Text>
      </MediaContextProvider>
      <Fade>
        <Blocks row wrap>
          {blocks.slice(0, 2).map((item, index) => (
            <Block key={index} {...item} />
          ))}
        </Blocks>
      </Fade>
      {cta ? <CTA {...cta} /> : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  position: relative;

  @media (max-width: 1199px) {
    padding-top: 5.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
  padding: 0 10vw;

  @media (max-width: 1199px) {
    font-size: 3.125rem;
    line-height: 3.75rem;
    padding: 0 8.454vw;
    text-align: center;
  }
`

const Text = styled(FlexBox)`
  margin-top: 1rem;
  padding: 0 10vw;
  > div {
    width: 50%;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.375rem;
  line-height: 1.875rem;
  padding-right: 3.542vw;

  @media (max-width: 1199px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-right: 0;
    text-align: center;
  }
`

const Hashtag = styled(Parallax)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 200;
  letter-spacing: 0.3125rem;
  line-height: 3.75rem;
  opacity: 0.16;
  text-align: right;
  text-transform: uppercase;
  transform: translateY(-0.625rem);
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 200;
  letter-spacing: 0.1875rem;
  line-height: 2.25rem;
  opacity: 0.16;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
`

const Blocks = styled(FlexBox)`
  margin-top: 2rem;
  padding: 0 10vw;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 40%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
    padding: 0 8.454vw;
    &:after {
      height: calc(75% + 3.75rem);
      bottom: -3.75rem;
    }
  }
`

const CTA = styled(Button)`
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 50%;

  @media (max-width: 767px) {
    display: block;
    width: calc(100% - 16.908vw);
    margin-top: 0;
    position: relative;
    bottom: auto;
    left: 8.454vw;
    text-align: center;
  }
`
