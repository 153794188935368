import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Poi, Props as PoiProps } from './Poi'

export interface Props {
  poi: PoiProps[]
  subtitle?: string
  title?: string
}

export const InteractiveMap = memo(function InteractiveMap({
  poi,
  subtitle,
  title,
}: Props) {
  if (poi.length < 1) {
    return null
  }

  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <>
          <Head dial={8} inline row>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          </Head>
          <Map>
            {poi.map((item, index) => (
              <Poi key={index} {...item} />
            ))}
          </Map>
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  text-align: center;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Head = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 1.875rem 4.625rem 2.5rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  line-height: 6rem;
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-left: 5rem;
  transform: translateY(-0.625rem);
`

const Map = styled.div`
  width: 100%;
  padding-bottom: 41%;
  background: url('/interactive-map.jpg') no-repeat center;
  background-size: cover;
  position: relative;
`
