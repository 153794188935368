import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  return (
    <Container>
      {icon ? (
        <Icon dial={5}>
          <SVG src={icon} width="28" height="28" alt={label} />
        </Icon>
      ) : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled.div`
  width: 33.333%;
  margin-top: 3.25rem;
  text-align: center;

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }

  @media (max-width: 767px) {
    width: 50%;
  }
`

const Icon = styled(FlexBox)`
  width: 5rem;
  height: 5rem;
  background: ${rgba(theme.colors.variants.neutralLight4, 0.1)};
  border-radius: 50%;
  margin: 0 auto 1.5rem;
`

const SVG = styled.img`
  width: 100%;
  height: 100%;
  max-width: 1.75rem;
  max-height: 1.75rem;
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  letter-spacing: 0.0187rem;
  line-height: 1.25rem;
  &:after {
    content: '';
    display: block;
    width: 1.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.12;
    margin: 1rem auto 0;
  }
`
