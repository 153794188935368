import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useLockedBody from 'app/utils/useLockedBody'
import React, { memo, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  stickerSubtitle?: string
  stickerTitle?: string
  title?: string
}

export const Sticker = memo(function Sticker({
  cta,
  description,
  image,
  stickerSubtitle,
  stickerTitle,
  title,
}: Props) {
  const [modalStickerStatus, setModalStickerStatus] = useState(false)
  const [_locked, setLocked] = useLockedBody()

  return (
    <Container>
      {stickerTitle || stickerSubtitle ? (
        <Label
          dial={5}
          onClick={() => {
            if (!modalStickerStatus) {
              setModalStickerStatus(true)
              setLocked(true)
            } else {
              setLocked(false)
              setModalStickerStatus(false)
            }
          }}
        >
          <LabelTitle>{stickerTitle}</LabelTitle>

          <LabelSubtitle>{stickerSubtitle}</LabelSubtitle>
        </Label>
      ) : null}

      <Modal
        className={modalStickerStatus ? 'open' : ''}
        dial={5}
        onClick={() => {
          setLocked(false)
          setModalStickerStatus(false)
        }}
      >
        <Wrapper onClick={(e) => e.stopPropagation()} row stretch>
          {image ? (
            <ImageWrap>
              <LazyLoadComponent>
                <Image {...image} />
              </LazyLoadComponent>
            </ImageWrap>
          ) : null}

          <TextWrap className={!image ? 'full-width' : undefined} dial={4}>
            <ModalClose
              onClick={() => {
                setLocked(false)
                setModalStickerStatus(false)
              }}
            />
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta ? <Button {...cta} /> : null}
          </TextWrap>
        </Wrapper>
      </Modal>
    </Container>
  )
})

const Container = styled.aside`
  padding: 0 1.5rem;
`

const Label = styled(FlexBox)`
  width: 11.25rem;
  height: 11.25rem;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  letter-spacing: 0.0175rem;
  line-height: 1rem;
  padding: 2.75rem;
  position: absolute;
  bottom: 6rem;
  right: 8rem;
  text-align: center;
  z-index: 2;
  &:hover {
    &:after {
      transform: scale(1.05);
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    border-radius: 50%;
  }
  &:before {
    width: 11.25rem;
    height: 11.25rem;
    opacity: 0.2;
    top: 0;
    left: 0;
  }
  &:after {
    width: 9.875rem;
    height: 9.875rem;
    top: 0.6875rem;
    left: 0.6875rem;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1199px) {
    align-items: center;
    width: 100%;
    height: auto;
    top: 4.375rem;
    bottom: auto;
    left: 0;
    padding: 1.25rem 1.25rem;
    transform: none;
    &:before {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
    &:after {
      display: none;
    }
  }
`

const LabelTitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-weight: 700;
  margin-bottom: 0.25rem;
  position: relative;
  text-transform: uppercase;
  z-index: 3;

  @media (max-width: 1199px) {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const LabelSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  position: relative;
  z-index: 3;

  @media (max-width: 1199px) {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Modal = styled(FlexBox)`
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

const ModalClose = styled.div`
  width: 1.625rem;
  height: 1.625rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  &:hover {
    &:before,
    &:after {
      transform: none;
    }
  }
  &:before,
  &:after {
    content: '';
    width: 1.25rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    position: absolute;
    top: 0.75rem;
    left: 0.1875rem;
    transition: 0.3s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  min-height: 55.6vh;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: auto;

  @media (max-width: 1199px) {
    min-height: auto;
  }
`

const ImageWrap = styled.div`
  width: 32%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05);
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const TextWrap = styled(FlexBox)`
  width: 68%;
  padding: 6.5rem 6.125rem 6.5rem 6.875rem;
  position: relative;
  &.full-width {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 3.75rem 1.5rem 3rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1199px) {
    margin-bottom: 1.5rem;
  }
`

const Description = styled.div`
  display: -webkit-box;
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;

  ul {
    padding: inherit;
    li {
      border-bottom: 0.0625rem solid
        ${({ theme }) => theme.colors.variants.neutralLight2};
      padding: 0.75rem 0 0.75rem 0.875rem;
      position: relative;
      &:first-of-type {
        &:before {
          top: 0.75rem;
        }
        padding-top: 0;
      }
      &:last-of-type {
        border: none;
        padding-bottom: 0;
      }
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark3};
        border-radius: 50%;
        position: absolute;
        top: 1.5rem;
        left: 0;
      }
    }
  }
`
