import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services?: ServiceProps[]
  subtitle?: string
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
  subtitle,
  title,
}: Props) {
  if (services && services?.length < 1) {
    return null
  }

  return (
    <Container dial={5} row wrap tag="section">
      <LeftSide>
        <Fade bottom distance="3.75rem">
          <Services dial={3} row wrap space="between">
            {services?.map((item, index) => (
              <Service key={index} {...item} />
            ))}
          </Services>
        </Fade>
      </LeftSide>
      <RightSide>
        <Fade right delay={600}>
          <>
            {title ? <Title>{title}</Title> : null}
            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          </>
        </Fade>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 12.5rem 0;

  @media (max-width: 1199px) {
    display: none;
  }
`

const LeftSide = styled.div`
  width: 56%;
  padding-left: 12.917vw;
`

const Services = styled(FlexBox)`
  margin-top: -3.25rem;
`

const RightSide = styled.div`
  width: 44%;
  padding-left: 8.472vw;
  position: relative;
  &:after {
    content: '';
    width: 21.944vw;
    height: 21.944vw;
    max-height: 18.75rem;
    background: url('/wave.svg') no-repeat;
    background-size: cover;
    opacity: 0.16;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`

const Title = styled.h2`
  max-width: 368px;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
`

const Subtitle = styled.h3`
  max-width: 368px;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.875rem;
`
