import styled from '@emotion/styled'
import { BeachParagraph } from 'app/components/BeachParagraph'
import { BlogPreview } from 'app/components/BlogPreview'
import { DigitalConcierge } from 'app/components/DigitalConcierge'
import { FamilyParagraph } from 'app/components/FamilyParagraph'
import { Hero } from 'app/components/Hero'
import { InteractiveMap } from 'app/components/InteractiveMap'
import { Intro } from 'app/components/Intro'
import { MainParagraph } from 'app/components/MainParagraph'
import { MainSlider } from 'app/components/MainSlider'
import { OffersPreview } from 'app/components/OffersPreview'
import { SEO } from 'app/components/SEO'
import { ServicesPreview } from 'app/components/ServicesPreview'
import { SocialReviews } from 'app/components/SocialReviews'
import { Sticker } from 'app/components/Sticker'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { VisualParagraph } from 'app/components/VisualParagraph'
import { ZigZag } from 'app/components/ZigZag'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Props as SidebarProps, Sidebar } from 'app/containers/Sidebar'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  sidebarProps?: SidebarProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.sidebarProps ? (
        <Sidebar
          languageCode={context.languageCode}
          {...context.sidebarProps}
        />
      ) : null}
      <GoogleAnalytics />
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.stickerProps ? <Sticker {...context.stickerProps} /> : null}
      {context.mainParagraphProps ? (
        <MainParagraph {...context.mainParagraphProps} />
      ) : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.servicesPreviewProps ? (
        <ServicesPreview {...context.servicesPreviewProps} />
      ) : null}
      {context.beachParagraphProps ? (
        <BeachParagraph {...context.beachParagraphProps} />
      ) : null}
      {context.zigZagProps ? (
        <ZigZag variant="trim" {...context.zigZagProps} />
      ) : null}
      {context.mainSliderProps ? (
        <MainSlider {...context.mainSliderProps} />
      ) : null}
      {context.familyParagraphProps ? (
        <FamilyParagraph {...context.familyParagraphProps} />
      ) : null}
      {context.interactiveMapProps ? (
        <InteractiveMap {...context.interactiveMapProps} />
      ) : null}
      {context.visualParagraphProps ? (
        <VisualParagraph {...context.visualParagraphProps} />
      ) : null}
      {context.offersPreviewProps ? (
        <OffersPreview {...context.offersPreviewProps} />
      ) : null}
      {context.blogPreviewProps ? (
        <BlogPreview {...context.blogPreviewProps} />
      ) : null}
      {context.socialReviewsProps ? (
        <SocialReviews {...context.socialReviewsProps} />
      ) : null}
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media greaterThanOrEqual="desktopSmall">
          {context.digitalConciergeProps ? (
            <DigitalConcierge {...context.digitalConciergeProps} />
          ) : null}
        </Media>
      </MediaContextProvider>
      {context.footerProps ? (
        <Footer
          appTitle={
            context.digitalConciergeProps
              ? context.digitalConciergeProps.title
              : null
          }
          appSubtitle={
            context.digitalConciergeProps
              ? context.digitalConciergeProps.subtitle
              : null
          }
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.footerProps}
        />
      ) : null}
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
