import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Fade from 'react-reveal/Fade'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export interface Props {
  cta?: ButtonProps
  description?: string
  hashtag?: string
  mainImage?: ImageProps
  secondaryImage?: ImageProps
  subtitle?: string
  title?: string
}

export const FamilyParagraph = memo(function FamilyParagraph({
  cta,
  description,
  hashtag,
  mainImage,
  secondaryImage,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      {hashtag ? <Hashtag>{hashtag}</Hashtag> : null}
      <Fade className="paragraph-hashtag" left distance="7.5rem">
        <Text>
          {title ? <Title>{title}</Title> : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {cta ? <Button iconArrowRight variant="S" {...cta} /> : null}
        </Text>
      </Fade>
      {secondaryImage ? (
        <SecondaryImage>
          <LazyLoadImage effect="blur" {...secondaryImage} />
        </SecondaryImage>
      ) : null}
      {mainImage ? (
        <ParallaxProvider>
          <Parallax y={[-15, 0]}>
            <MainImage>
              <LazyLoadImage effect="blur" {...mainImage} />
            </MainImage>
          </Parallax>
        </ParallaxProvider>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 11.5625rem 0 12.5rem;
  position: relative;
  &:after {
    content: '';
    width: calc(50% - 10vw);
    height: 7.625rem;
    background: url('/wave-dark.svg') no-repeat center;
    background-size: 200%;
    opacity: 0.2;
    position: absolute;
    bottom: 12.5rem;
    left: 10vw;
  }
  .react-reveal {
    padding: 7.625rem 0;
    position: relative;
    z-index: 3;
  }
  .parallax-outer {
    width: 23rem;
    position: absolute;
    bottom: 15.375rem;
    left: calc(50% + 7.639vw);
    z-index: 4;
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    padding: 3.75rem 8.454vw;
    &:before {
      content: '';
      width: 100%;
      height: 75%;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:after {
      display: none;
    }

    .react-reveal {
      order: 2;
      padding: 0;
    }
    .parallax-outer {
      width: 100%;
      margin-top: 3.75rem;
      position: relative;
      bottom: auto;
      left: auto;
      z-index: 1;
    }
    .parallax-inner {
      transform: none !important;
    }
  }
`

const Text = styled(FlexBox)`
  width: 70%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 4.5rem 10vw 6rem;

  @media (max-width: 1199px) {
    background: none;
    width: 100%;
    margin-top: 1.875rem;
    padding: 0;
  }
`

const Title = styled.h2`
  max-width: 37.5vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.5rem;
    line-height: 3rem;
  }
`

const Subtitle = styled.h3`
  max-width: 37.5vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-top: 2.25rem;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 1.875rem;
  }
`

const Description = styled.div`
  max-width: 37.5vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  margin-top: 1.25rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Hashtag = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 200;
  letter-spacing: 0.3125rem;
  line-height: 3.75rem;
  opacity: 0.16;
  text-transform: uppercase;
  position: absolute;
  top: 12.5rem;
  left: 10vw;

  @media (max-width: 1199px) {
    width: 100%;
    font-size: 1.875rem;
    letter-spacing: 0.1875rem;
    line-height: 2.25rem;
    position: relative;
    top: auto;
    left: auto;
    text-align: center;
  }
`

const MainImage = styled.div`
  width: 100%;
  padding-bottom: 133%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    padding-bottom: 33%;
  }

  @media (max-width: 767px) {
    padding-bottom: 133%;
  }
`

const SecondaryImage = styled.div`
  width: 50%;
  height: calc(100% - 20.125rem);
  position: absolute;
  top: 7.625rem;
  right: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    display: none;
  }
`
