import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  description?: string
  source?: string
  title?: string
  user?: string
}

export const Review = memo(function Review({
  description,
  source,
  title,
  user,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {user ? <User>{user}</User> : null}
      {source && (source === 'facebook' || source === 'tripadvisor') ? (
        <Source alt={source} src={`/${source}.svg`} width="140" height="22" />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  max-width: 370px;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
  text-align: center;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2rem;
  line-height: 2.375rem;
  margin-bottom: 0.75rem;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.375rem;
`

const User = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.0437rem;
  line-height: 0.9375rem;
  margin-top: 1.875rem;
  text-transform: uppercase;
`

const Source = styled.img`
  width: auto;
  height: 1.375rem;
  margin: 0.8125rem auto 0;
`
