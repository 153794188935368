import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export interface Props {
  image?: ImageProps
  subtitle?: string
  title?: string
}

export const Slide = memo(function Slide({ image, subtitle, title }: Props) {
  return (
    <Container>
      {image ? <Image {...image} /> : null}
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div`
  padding-bottom: 49%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark0, 0)},
      ${rgba(theme.colors.variants.neutralDark0, 0.7)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 116%;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 9.75rem;
  left: 3.75rem;
  z-index: 2;

  @media (max-width: 1199px) {
    bottom: 6.875rem;
    left: 1.875rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 400;
  letter-spacing: 0.0563rem;
  line-height: 2.25rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.5rem;
    letter-spacing: 0.045rem;
    line-height: 1.875rem;
  }
`

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.875rem;
  opacity: 0.5;
  margin-top: 0.5625rem;

  @media (max-width: 1199px) {
    display: none;
  }
`
