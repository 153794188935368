import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

import { Post, Props as PostProps } from './Post'

function PrevArrow(props: any) {
  return (
    <Arrow className="slider-prev" dial={5} onClick={props.onClick}>
      <ArrowLeft />
    </Arrow>
  )
}

function NextArrow(props: any) {
  return (
    <Arrow className="slider-next" dial={5} onClick={props.onClick}>
      <ArrowRight />
    </Arrow>
  )
}

export interface Props {
  articles?: PostProps[]
  cta?: ButtonProps
  description?: string
  title?: string
}

export const BlogPreview = memo(function BlogPreview({
  articles,
  cta,
  description,
  title,
}: Props) {
  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 2000,
  }

  if (!articles) {
    return null
  }

  if (articles.length < 1) {
    return null
  }

  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <Wrapper row wrap stretch>
          <LeftSide>
            <Text>
              {title ? <Title>{title}</Title> : null}
              {description ? (
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : null}
              {cta ? <CTA iconArrowRight variant="S" {...cta} /> : null}
            </Text>
          </LeftSide>
          <RightSide>
            <Slider lazyLoad="progressive" {...settings}>
              {articles
                .sort((a: any, b: any) => (a.created < b.created ? 1 : -1))
                .slice(0, 5)
                .map((item, index) => (
                  <Post key={index} {...item} />
                ))}
            </Slider>
          </RightSide>
        </Wrapper>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 12.5rem 10vw 3.75rem;

  @media (max-width: 1199px) {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    padding: 0;
  }
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;
  padding: 3.75rem 5.486vw 9rem 6.806vw;
  position: relative;
  &:before {
    content: '';
    width: 60vw;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    padding: 3.75rem 8.454vw;
    text-align: center;
    &:before {
      display: none;
    }
  }
`

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  position: relative;
  z-index: 2;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
  margin-bottom: 1rem;

  @media (max-width: 1199px) {
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-size: 2rem;
    line-height: 2.375rem;
    margin-bottom: 0;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-top: 1.125rem;

  @media (max-width: 1199px) {
    max-width: 160px;
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
    font-size: 1rem;
    line-height: 1.375rem;
    margin: 1.25rem auto 0;
  }
`

const CTA = styled(Button)`
  margin-top: 4.125rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
    span {
      display: none;
    }
    svg {
      width: 5.625rem;
      height: 5.625rem;
      stroke-width: 0.5;
      transform: none;
      circle {
        opacity: 0.2;
      }
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  transform: translateY(3.75rem);
  position: relative;

  .slick-slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .slick-list,
    .slick-track {
      height: 100%;
      z-index: 2;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Arrow = styled(FlexBox)`
  width: 5.25rem;
  height: 5.25rem;
  border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.5)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: -2.625rem;
  z-index: 3;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    border-color: transparent;
  }
  &.slider-prev {
    bottom: 3.75rem;
  }
  &.slider-next {
    bottom: 9.75rem;
  }
  svg {
    width: auto;
    height: 2.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 1;
  }
`
