import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useRef, useState } from 'react'

export interface Props {
  label: string
  x_axis: number
  y_axis: number
}

export const Poi = memo(function Poi({ label, x_axis, y_axis }: Props) {
  const activeTooltip = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeTooltip.current && !activeTooltip.current.contains(e.target)) {
      setShowTooltip(false)
    } else {
      setShowTooltip(true)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <Container
      className={showTooltip ? 'active' : ''}
      style={{ top: `${y_axis}%`, left: `${x_axis}%` }}
      onClick={() => setShowTooltip(true)}
      ref={activeTooltip}
    >
      <Tooltip className={showTooltip ? 'visible' : ''}>
        <Label>{label}</Label>
      </Tooltip>
    </Container>
  )
})

const Container = styled.div`
  width: 3.056vw;
  height: 3.056vw;
  border: 2px solid ${rgba(theme.colors.variants.neutralDark1, 0.3)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
    border-color: transparent;
  }
  &.active {
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  &:before,
  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:before {
    width: 1.389vw;
    height: 1.389vw;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
  &:after {
    width: 0.417vw;
    height: 0.417vw;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    transform: translate(-50%, -50%) scale(0);
    transition: 0.25s ease-in-out;
  }
`

const Tooltip = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 2.5rem;
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 1.375rem);
  left: 50%;
  transform: translate(-50%, 0.75rem);
  transition: 0.3s ease-in-out;
  z-index: -1;
  &.visible {
    opacity: 1;
    transform: translate(-50%, 0);
    z-index: 1;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 0.75rem solid transparent;
    border-right: 0.75rem solid transparent;
    border-top: 0.75rem solid
      ${({ theme }) => theme.colors.variants.neutralDark1};
    position: absolute;
    bottom: -0.75rem;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.875rem;
  text-align: center;
  white-space: nowrap;
  &:before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    margin: 0 auto 0.375rem;
  }
`
