import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Offer, Props as OfferProps } from 'app/components/OffersList/Offer'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { ArrowRightCircle } from 'react-feather'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

export interface Props {
  description?: string
  languageCode: string
  offers: OfferProps[]
  pageURL?: string
  title?: string
}

export const OffersPreview = memo(function OffersPreview({
  description,
  languageCode,
  offers,
  pageURL,
  title,
}: Props) {
  const settings = {
    arrows: false,
    centerMode: true,
    centerPadding: '24px',
    dots: false,
    infinite: true,
  }

  if (offers.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      ) : null}
      <Fade bottom distance="3.75rem">
        <Head dial={4} row wrap>
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
          {pageURL ? (
            <CTA to={pageURL}>
              {useVocabularyData('all-offers', languageCode)}
              <ArrowRightCircle />
            </CTA>
          ) : null}
        </Head>
      </Fade>
      <Fade bottom distance="3.75rem">
        <>
          <style type="text/css">{mediaStyle}</style>
          <MediaContextProvider>
            <Media lessThan="ipadVertical">
              <Carousel lazyLoad="progressive" {...settings}>
                {offers.slice(0, 3).map((item, index) => (
                  <Offer key={index} {...item} />
                ))}
              </Carousel>
            </Media>
            <Media greaterThanOrEqual="ipadVertical">
              <Offers row wrap stretch>
                {offers.slice(0, 3).map((item, index) => (
                  <Offer key={index} {...item} />
                ))}
              </Offers>
            </Media>
          </MediaContextProvider>
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 12.5rem 10vw 0;

  @media (max-width: 1199px) {
    padding: 7.5rem 0 10.3125rem;
  }

  @media (max-width: 1023px) {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 5rem;
  font-weight: 400;
  line-height: 6rem;
  margin-bottom: 1rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 0;
    padding: 0 8.454vw;
    text-align: center;
  }
`

const Head = styled(FlexBox)`
  @media (max-width: 1199px) {
    padding: 0 8.454vw;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.5rem;
  line-height: 1.875rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const CTA = styled(Link)`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.9375rem;
  font-weight: 600;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-left: 13.333vw;
  position: relative;
  z-index: 2;
  &:hover {
    text-decoration: underline;
  }
  svg {
    display: none;
  }

  @media (max-width: 1199px) {
    display: flex;
    align-items: center;
    margin: 1.875rem auto 0;

    svg {
      display: block;
      width: auto;
      height: 3.75rem;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      stroke-width: 0.6;
      transform: translateX(-1.4375rem);
      position: relative;
      z-index: -1;
    }
  }
`

const Offers = styled(FlexBox)`
  margin-top: 4.625rem;
  margin-right: -1.5rem;
  a {
    display: block;
    width: calc(33.333% - 1.5rem);
    padding-bottom: 47%;
    margin-right: 1.5rem;
  }

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
    margin-right: -0.75rem;
    padding: 0 8.454vw;
    a {
      width: calc(33.333% - 0.75rem);
      margin-right: 0.75rem;
      padding-bottom: 0;
    }
  }
`

const Carousel = styled(Slider)`
  margin-top: 3.125rem;

  .slick-track {
    display: flex;
    margin-left: 0;
  }
  .slick-slide {
    margin: 0 6px;
    a {
      display: block;
      padding-bottom: 0;
    }
  }
`
